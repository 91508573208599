import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const urlsToFetch = [
    {
        label:  'production',
        joiner: '&',
        url:    'https://tns-counter.ru/e/ec01',
    },
    {
        label:  'test',
        joiner: '?',
        url:    'https://counter.smotrim.ru/e/ec02',
    }
];

const setURLC = (id, type) => {
    let mediaType;

    switch (type) {
        case 1:
            mediaType = 'live';
            break;
        case 2:
            mediaType = 'video';
            break;
        case 6:
            mediaType = 'channel';
            break;
        case 7:
            mediaType = 'audio';
            break;
        default:
            mediaType = 'video';
    }

    return encodeURIComponent(`https://smotrim.ru/${mediaType}/${ id }`);
}

const setUrls = (data, config) => {

    let params  = [];
    let smid    = Cookies.get('smid');
    let decoded = smid ? jwtDecode(smid) : null;
    let uid     = decoded ? decoded?.sub : null;

    data.cid  = config.cid;
    data.tms  = config.tms;
    data.idc  = config.idc;
    data.urlc = setURLC(data.idlc, data.type);

    if (uid) {
        data.hid = uid;
    }

    for (let param in data) {
        if (data.hasOwnProperty(param)) {
            params.push(`${ param }=${ data[param] }`);
        }
    }

    return urlsToFetch.map(url => {
        return {
            label: url.label,
            link:  `${ url.url }${ url.joiner }${ params.join('&') }`
        };
    })
}

class MediaScope {
    constructor(config) {

        this.config = config;

        window.addEventListener('message', event => {
            let data = null;

            try {
                data = JSON.parse(event.data);
            } catch (error) {
                data = {};
            }

            if (data && data.hasOwnProperty('name') && data.hasOwnProperty('info')) {
                if (data.name === 'stat.hit') {
                    this.hit(data.info);
                }
            }
        })
    }

    hit(params) {
        let urls = setUrls(params, this.config);

        for (let url of urls) {
            fetch(url.link, {
                method:      'GET',
                credentials: 'include'
            })
                .then(response => {
                    console.log(`%c### MediaScope: ${ url.label } STATUS = ${ response.status }`, 'background: #018521; padding: 5px 10px;');
                })
                .catch(error => {
                    console.log(`%c### MediaScope: ${ url.label } FAILED`, 'background: #e30112; padding: 5px 10px;');
                    console.error(error);
                });
        }
    }
}

window.MSC = MediaScope;
